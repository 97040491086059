@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

.nav-area {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 12px;
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: end;
}
.navbar {
  justify-content: flex-end;
}.nav-menu a {
  text-decoration: none;
}
